// extracted by mini-css-extract-plugin
export var bgGrey100 = "finance-checkout-product-module--bg-grey-100--647b8";
export var bgGrey150 = "finance-checkout-product-module--bg-grey-150--5471b";
export var bgGrey200 = "finance-checkout-product-module--bg-grey-200--89297";
export var bgGrey300 = "finance-checkout-product-module--bg-grey-300--03a19";
export var bgGrey400 = "finance-checkout-product-module--bg-grey-400--66dd0";
export var bgGrey500 = "finance-checkout-product-module--bg-grey-500--8c5da";
export var bgGrey600 = "finance-checkout-product-module--bg-grey-600--c0768";
export var bgGrey700 = "finance-checkout-product-module--bg-grey-700--35100";
export var bgGrey800 = "finance-checkout-product-module--bg-grey-800--a4cce";
export var bgGrey900 = "finance-checkout-product-module--bg-grey-900--4947a";
export var productImage = "finance-checkout-product-module--productImage--37b47";
export var textGrey100 = "finance-checkout-product-module--text-grey-100--14d6c";
export var textGrey150 = "finance-checkout-product-module--text-grey-150--db33a";
export var textGrey200 = "finance-checkout-product-module--text-grey-200--3ef42";
export var textGrey300 = "finance-checkout-product-module--text-grey-300--08b1e";
export var textGrey400 = "finance-checkout-product-module--text-grey-400--2685f";
export var textGrey500 = "finance-checkout-product-module--text-grey-500--c4058";
export var textGrey600 = "finance-checkout-product-module--text-grey-600--3cf91";
export var textGrey700 = "finance-checkout-product-module--text-grey-700--834dd";
export var textGrey800 = "finance-checkout-product-module--text-grey-800--5bf99";
export var textGrey900 = "finance-checkout-product-module--text-grey-900--f3394";