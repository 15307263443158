import React from "react"
import {connect} from "react-redux"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "../components/Layout"
import CheckoutStepper, { CheckoutStep } from "../components/CheckoutStepper"
import { financeSelectors } from "../state/finance"
import {Link} from "gatsby"
import * as styles from "./finance-checkout-product.module.scss"
import parse from 'html-react-parser';
import {dataLayerUpdate} from "../../src/api/dataLayer/gaDataLayer";

const CheckoutFinanceProductPage = ({ location, product, quote }) => {
  dataLayerUpdate('finance_start', product)

  const { TermQuoted, RegularPayment } = quote?.Figures || {}
  const { extraFee } = product?.beforeAddToBasketNotification || {}
  const productDescription = product ? product.description : ""

  return (
    <Layout
      staticSeo={{ seoTitle: "Checkout finance", seoDescription: "Checkout finance process" }}
      location={{ pathname: location.pathname }}
    >
      <CheckoutStepper activeStepNumber={1}>
         <CheckoutStep pageLink={location.pathname} title="Choose a bike"/>
         <CheckoutStep pageLink={"/finance-checkout-accessories"} title="Choose accessories"/>
         <CheckoutStep pageLink={"/finance-checkout-data"} title="Choose delivery method"/>
         <CheckoutStep pageLink={"/finance-checkout-summary"} title="Finish"/>
      </CheckoutStepper>
      <Container className="my-5">
        <Row>
          <Col xs={12}>
            <h3 className="pb-4">Your selected product - {product?.name}</h3>
            <p className="m-0">{parse(productDescription)}
            </p>
            <hr className="my-3 my-lg-5" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5} lg={4}>
            <div className="d-flex flex-column align-items-sm-stretch">
              <p><strong>Choosen bike</strong><br/>{product?.name}</p>
              <p><strong>Price</strong><br/>{product?.price}</p>
              {extraFee?.feeTitle && <p><strong>{extraFee.feeTitle}</strong><br/>{process.env.CURRENCY_SYMBOL}{extraFee.feeValue}</p> }
              <p><strong>Financing</strong><br/>
                {`Pay ${process.env.CURRENCY_SYMBOL}${RegularPayment} for ${TermQuoted} months`}
              </p>
            </div>
          </Col>
          <Col xs={12} md={7} lg={8}>
            <img alt="Product image" src={product?.featuredImage.node.sourceUrl} className={styles.productImage} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} >
            <hr className="my-3 my-lg-5" />
          </Col>
          <Col xs={6}>

          </Col>
          <Col xs={12} sm={6}>
            <Button id="financeStartNext" as={Link} to="/finance-checkout-accessories" className="w-100">Next step</Button>
          </Col>
        </Row>
      </Container>


    </Layout>
  )
}

const mapStateToProps = state => ({
  product: financeSelectors.getProduct(state),
  quote: financeSelectors.getQuote(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (CheckoutFinanceProductPage)
